import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleLine, faPeopleCarryBox, faDumbbell, faDollarSign, faBasketball } from '@fortawesome/free-solid-svg-icons'
const mainVideo = require('../assets/hoopchaser-background-video1.mp4');

function About({data, toggleSlider}) {

  useEffect(() => {
    toggleSlider(false);
  }, []);

  return (
    <div id='home-page' className='flex flex-auto flex-col flex-grow'>
      <section className='main-section relative'>
        <div className='section-content-top'>
          <h1 className='w-fit text-5xl sm:text-7xl font-bold text-white'>HOOPCHASER</h1>
        </div>
      </section>
      <section className='main-section' id='main-section-background-2'>
        <div className='section-content'>
          <h1 className='w-fit text-3xl sm:text-4xl font-bold'>ABOUT</h1>
          <p className='w-5/6 md:w-4/6 text-center'>
            Hoopchaser is an innovative online marketplace that allows groups of athletes to book time at local indoor courts and gymnasiums.
            </p>
        </div>
      </section>
      <section className='main-section' id='main-section-background-3'>
        <div className='section-content'>
          <h1 className='w-fit text-3xl sm:text-4xl font-bold'>MISSION</h1>
          <p className='w-5/6 md:w-4/6 text-center'>
            We are a purpose-driven company, dedicated to creating equitable access to indoor courts and sports facilities for groups of athletes.
            Our mission is to empower communities through organized sports opportunities. We believe in the transformative power of sports and that
            all of our customers should have access to affordable opportunities that match their interests and needs.
          </p>
        </div>
      </section>
      <section className='main-section' id='main-section-background-4'>
        <div className='section-content'>
          <h1 className='w-fit text-3xl sm:text-4xl font-bold'>VALUES</h1>
          <div className='text-center flex flex-col lg:flex-row gap-5 sm:gap-10'>
            <div className='w-full lg:w-1/6 gap-1'>
              <FontAwesomeIcon style={{color: '#746cc0'}} icon={faPeopleLine} size='xl'/>
              <p>Equity in Access</p>
            </div>
            <div className='w-full lg:w-1/6'>
              <FontAwesomeIcon style={{color: '#23479a'}} icon={faPeopleCarryBox} size='xl'/>
              <p>Community Impact</p>
            </div>
            <div className='w-full lg:w-1/6'>
              <FontAwesomeIcon style={{color: 'black'}} icon={faDumbbell} size='xl'/>
              <p>Health and Well-being</p>
            </div>
            <div className='w-full lg:w-1/6'>
              <FontAwesomeIcon style={{color: 'green'}} icon={faDollarSign} size='xl'/>
              <p>Affordability</p>
            </div>
            <div className='w-full lg:w-1/6'>
              <FontAwesomeIcon style={{color: '#F88158'}} icon={faBasketball} size='xl'/>
              <p>Innovation in Sports</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
