const FacebookIcon = require('../assets/icon-facebook-color.png');
const InstagramIcon = require('../assets/icon-instagram-color.png');
const YoutubeIcon = require('../assets/icon-youtube-color.png');


function Footer() {
  return (
    <footer className='flex flex-col justify-center w-full p-6 gap-5 items-center justify-center items-center relative'>

      <div className='flex flex-row justify-center items-center w-full gap-10'>
        <div className='footer-item'>
          <a href='https://www.facebook.com/hoopchaserusa' target="_blank"  rel="noreferrer noopener">
            <img className='h-11' src={FacebookIcon} alt='facebook-icon'></img>
          </a>
        </div>
        <div className='footer-item'>
          <a href='https://www.instagram.com/hoopchaserusa' target="_blank"  rel="noreferrer noopener">
            <img className='h-11' src={InstagramIcon} alt='facebook-icon'></img>
          </a>
        </div>
        <div className='footer-item'>
          <a href='https://www.youtube.com/@Hoopchaserusa' target="_blank"  rel="noreferrer noopener">
            <img className='h-11 bg-white rounded-lg' src={YoutubeIcon} alt='facebook-icon'></img>
          </a>
        </div>

      </div>
        <div className='footer-item text-center'>
          <div>info@hoopchaser.com</div>
          <div>(207) 680-0924</div>
        </div>
      <div>
        © 2023 Hoopchaser - All Rights Reserved.
      </div>

    </footer>
  );
}

export default Footer;
