import NavBar from './NavBar';
const Logo = require('../assets/hoop-chaser-logo-white.png');

function Header({toggleSlider}) {

  const headerClick = (e) => {
    if(e.target === e.currentTarget) {
      toggleSlider(false);
   }
  }

  return (
    <header className='flex flex-row items-center justify-between align-center h-18 sm:h-26 z-10' onClick={(e) => headerClick(e)}>
      <img className='m-4 h-20 sm:h-32' src={Logo} alt='Hooped In'></img>
      <NavBar toggleSlider={toggleSlider}/>
    </header>
  );
}

export default Header;
