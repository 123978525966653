import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import SignUp from './components/SignUp';
import News from './components/News';
import FairPlay from './components/FairPlay';
import Footer from './components/Footer';
import SliderNav from './components/SliderNav';

const mainVideo = require('./assets/hoopchaser-background-video1.mp4');

function App() {

  const [data, setData] = useState(null);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  const toggleSlider = (state) => {
    if (state !== undefined) {
      setSliderOpen(state);
    } else {
      setSliderOpen(!sliderOpen);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 800;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  useEffect(() => {
    fetch('/api')
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <div id='app'className='flex flex-col'>
      <Router>
        <Header toggleSlider={toggleSlider} />
        <div id='home-page-video-container'>
          <video id='home-page-video' src={mainVideo} autoPlay={true} muted={true} loop={true} playsInline={true}/>
        </div>
        <main className={`flex flex-grow flex-col ${sliderOpen && isMobile ? 'slid' : null} min-h-screen`} onClick={() => toggleSlider(false)}>
          <Routes>
            <Route path='/' element={<About data={data} toggleSlider={toggleSlider} />} />
            <Route path='/signupasrenter' element={<SignUp as={'renter'} toggleSlider={toggleSlider} />} />
            <Route path='/signupascourt' element={<SignUp as={'court'} toggleSlider={toggleSlider} />} />
            <Route path='/news' element={<News toggleSlider={toggleSlider} />} />
            <Route path='/fairplay' element={<FairPlay toggleSlider={toggleSlider} />} />
          </Routes>
        </main>
        <SliderNav sliderOpen={sliderOpen}/>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
