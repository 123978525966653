import { useEffect } from 'react';

function FairPlay({toggleSlider}) {

  useEffect(() => {
    toggleSlider(false);
  }, []);

  return (
    <div id='fairplay-container' className='bg-white m-auto flex flex-col gap-5 w-full sm:w-4/5 md:w-3/5 lg:w-1/2 z-5  mt-32 sm:mt-44 mb-28 sm:mb-20'>
      <h1 className='w-full text-center font-bold'>THE FAIR PLAY PROGRAM</h1>

      <div>
        <h3 className='inline font-bold'>Mission: </h3>
        <span>
          Hoopchaser is a purpose-driven company, dedicated to creating equitable access to indoor courts and sports facilities for groups of athletes.
          Our mission is to empower communities by creating opportunities for all to participate in organized sports. We believe in the transformative
          power of sports and that each of our customers should have access to affordable programming that fits their unique interests and needs.
        </span>
      </div>

      <div>
        <h3 className='inline font-bold'>Introduction: </h3>
        <span>
          The Fair Play Program was created to better serve groups that face barriers when securing court time in Maine, including those with 50% or more
          participants that are low income or underserved populations.
        </span>
      </div>

      <div>
        <h3 className='inline font-bold'>Details: </h3>
        <span>
          Groups that qualify can sign up to receive discounted rates on rental fees. The rest of the rental fee is covered through our Equity Partners Fund,
          through donations from partners, and sponsors. Businesses can become Equity Partners by donating to the fund. Sports facilities can become Equity
          Partners by offering their court for rent at the lower target rate to qualified groups.
        </span>
      </div>

      <div>
        <h3 className='inline font-bold'>Contact: </h3>
        <span>
          Groups interested in qualifying should email <a href="mailto:fairplay@hoopchaser.com">fairplay@hoopchaser.com</a> to determine if they are eligible.
          Facilities or businesses interested in becoming one of our Equity Partners should email <a href="mailto:info@hoopchaser.com">info@hoopchaser.com</a>
          for more information.
        </span>
      </div>

    </div>
  );
}

export default FairPlay;
