import React, { useState, useEffect } from 'react';

function AvailableCourts() {
  const [courtInfoOpen, setCourtInfoOpen] = useState({})

  const handleClick = (courtID) => {
    var updatedStatus = {};
    if (courtInfoOpen[courtID] === true) {
      updatedStatus[courtID] = false;
    } else {
      updatedStatus[courtID] = true;
    }

    setCourtInfoOpen(courtInfoOpen => ({
      ...courtInfoOpen,
      ...updatedStatus
    }));
  }
  const courts = [
    {
      id: 1,
      name: 'Lewiston Armory',
      address: '65 Central Ave Lewiston, ME',
      city: 'Lewiston, ME',
      sports: 'basketball, pickleball, volleyball',
      size: '1 Full Side Court (2 hoops)',
      availability: 'Thursdays 7:30pm-9:30pm',
      price: '$40/Hour, $75/2 Hours',
      website: 'https://www.facebook.com/LewistonArmory'
    },
    {
      id: 2,
      name: 'Ingersoll Arena',
      address: '48 Pettengill Park Auburn, ME 04210',
      city: 'Auburn, ME',
      sports: 'soccer, lacrosse, football',
      size: '1/2 field: 10k sq. ft. Full Field: 20k sq. Ft.',
      availability: 'Saturdays 4pm-7pm',
      price: '$85/hour half field, $115/hour full field',
      website: 'https://www.auburnmaine.gov/Pages/Government/Ingersoll-Arena'
    },
    {
      id: 3,
      name: 'Pineland Outdoor and Recreation',
      address: '25 Campus Drive New Gloucester, ME, 04260',
      city: 'New Gloucester, ME',
      sports: 'basketball, pickleball, volleyball',
      size: 'Standard size court',
      availability: 'Saturdays 4pm-6pm',
      price: '$65/hour',
      website: 'https://www.pinelandfarms.org/recreation/'
    },
  ]
  return (
    <div id='court-information' className='bg-black text-white flex flex-col gap-4 box-border relative'>

      <h2 className='w-full text-center font-bold' style={{ marginBottom: '10px'}}>AVAILABLE COURTS</h2>

      {
        courts.map((court) => {
          return (
            <div className='flex flex-col border border-white' key={`court-${court.id}`}>
              <div className='p-2 flex items-center justify-between gap-5'>
                <div className='flex gap-5 items-center'>
                  <h2 className='font-bold inline'>{court.name} <span className='text-xs ml-2'>({court.city})</span></h2>
                </div>
                <button className='btn-primary show-court-info w-24' style={{float: 'right'}} onClick={() => handleClick(court.id)}>
                { courtInfoOpen[court.id]
                ?
                  "Close"
                : "More Info"
                }
                </button>
              </div>

              <div className={`flex flex-col border-t ${courtInfoOpen[court.id] ? 'visible' : 'invisible h-0'}`}>
                <div className='flex flex-col gap-1 p-2'>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Address:</span>
                    <span className='w-full'>{court.address}</span>
                  </div>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Sports:</span>
                    <span className='w-full'>{court.sports}</span>
                  </div>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Size:</span>
                    <span className='w-full'>{court.size}</span>
                  </div>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Availability:</span>
                    <span className='w-full'>{court.availability}</span>
                  </div>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Price:</span>
                    <span className='w-full'>{court.price}</span>
                  </div>
                  <div className='flex flex-col sm:flex-row sm:gap-2'>
                    <span className='w-36 font-bold'>Website:</span>
                    <span className='w-full'><a href={court.website}>{court.name}</a></span>
                  </div>
                </div>
              </div>


            </div>
          )
        })
      }


    </div>
  );
}

export default AvailableCourts;
