import React, { useState, useEffect } from 'react';
const InterCityScramble = require('../assets/inter-city-scramble-poster.png');

function News({toggleSlider}) {

  useEffect(() => {
    toggleSlider(false);
  }, []);

  return (
    <>
      <div id='news-page' className='flex flex-auto flex-col flex-grow items-center mt-32 sm:mt-44 mb-28 sm:mb-20'>
        <article className='news-article bg-white w-full sm:w-4/5 md:w-3/5 p-10 rounded-lg'>
          <h2 className='font-bold'>Inter-City Scramble Pick-Up Tournaments</h2>
          <div className='news-article-content flex flex-col g-5 py-5'>
            <p>When: Sat Jan 20th, Sat Jan 27th, Sat Feb 10th, Sat Feb 17th</p>
            <p>Where: <a href='https://pinelandfarms.org/recreation/outdoor-center/open-gym/'>Pineland Recreation, New Gloucester</a></p>
            <p>Cost: First session FREE ($40 per player for other sessions)</p>
            <p>More Info:</p>
            <div className='news-article-flyer'>
              <img className='w-full w-full' alt='Inter-City Scramble Flyer' src={InterCityScramble}></img>
            </div>
          </div>
        </article>

      </div>
    </>
  );
}

export default News;