
import { useState } from 'react';
import { NavLink } from 'react-router-dom';


function SliderNav({sliderOpen}) {
  return (
    <div id='slider-nav' className={`flex flex-col items-center justify-center gap-10 text-lg absolute h-screen ${sliderOpen ? 'active' : null} min-[725px]:hidden`}>
      <NavLink className='slider-nav-item' to='/'>ABOUT</NavLink>
      <NavLink className='slider-nav-item' to='/signupasrenter'>RENT A COURT</NavLink>
      <NavLink className='slider-nav-item' to='/signupascourt'>LIST A COURT</NavLink>
      <NavLink className='slider-nav-item' to='/news'>NEWS & EVENTS</NavLink>
      <NavLink className='slider-nav-item' to='/fairplay'>FAIR PLAY</NavLink>
    </div>
  );
}

export default SliderNav;
