import React, { useState, useEffect } from 'react';

function CourtSignUp({toggleSlider, error, setError, setSuccessfulSignup}) {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Email');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressState, setAddressState] = useState('');
  const [addressZip, setAddressZip] = useState('');
  const [facilitySize, setFacilitySize] = useState('');
  const [comment, setComment] = useState('');

  const courtSubmit = (e: any) => {
    e.preventDefault();

    const formData = {
      EMAIL: email,
      FNAME: fname,
      LNAME: lname,
      PHONE: phone,
      CONT_METH: contactMethod,
      ADDR_1: addressLine1,
      ADDR_2: addressLine2,
      ADDR_CITY: addressCity,
      ADDR_STATE: addressState,
      ADDR_ZIP: addressZip,
      FAC_SIZE: facilitySize,
      COMMENT: comment,
      USER_TYPE: 'COURT'
    }

    fetch('/api/signupcourt', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        setSuccessfulSignup(true);
      }
    })
  };

  useEffect(() => {
    toggleSlider(false);
  }, []);

  return (
    <div id='court-signup-container' className='flex-col lg:flex-row'>
      <div id='court-signup-form-container' className='relative'>
        <h2 className='w-full text-center p-10 font-bold'>COURT SIGN-UP FORM</h2>
        <form onSubmit={courtSubmit} className='signup-form text-base flex flex-col lg:flex-none sm:grid sm:grid-cols-2' id='court-signup-form'>

          <label htmlFor='court-email'>Contact Email:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-email' onChange={(e) => setEmail(e.target.value)} required/>

          <label htmlFor='court-fname'>Contact First Name:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='contact-fname' onChange={(e) => setFname(e.target.value)} required/>

          <label htmlFor='court-lname'>Contact Last Name:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-lname' onChange={(e) => setLname(e.target.value)} required/>

          <label htmlFor='court-phone'>Phone:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-phone' onChange={(e) => setPhone(e.target.value)} required/>

          <label htmlFor='court-contact-method'>Best way to contact?</label>
          <select className='-mt-4 sm:mt-0' id='court-contact-method' onChange={(e) => setContactMethod(e.target.value)}>
            <option>Email</option>
            <option>Phone</option>
          </select>

          <label htmlFor='court-address-line-1'>Facility Address Line 1:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-address-line-1' onChange={(e) => setAddressLine1(e.target.value)} required/>

          <label htmlFor='court-address-line-2'>Facility Address Line 2:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-address-line-2' onChange={(e) => setAddressLine2(e.target.value)}/>

          <label htmlFor='court-address-city'>Facility City:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-address-city' onChange={(e) => setAddressCity(e.target.value)} required/>

          <label htmlFor='court-address-state'>Facility State:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-address-state' onChange={(e) => setAddressState(e.target.value)} required/>

          <label htmlFor='court-address-zip'>Facility Zip Code:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-address-zip' onChange={(e) => setAddressZip(e.target.value)} required/>

          <label htmlFor='court-size'>Facility Size (# of hoops etc.):</label>
          <input className='-mt-4 sm:mt-0' type='text' id='court-size' onChange={(e) => setFacilitySize(e.target.value)}/>

          <label htmlFor='court-comment' style={{gridColumn: 'span 2'}}>Anything else we should know?</label>
          <textarea
            className='-mt-4'
            type='text'
            id='court-comment'
            style={{gridColumn: 'span 2', height: '100px'}}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>

          <button className='btn-primary w-22 m-auto col-span-2' type="submit">Submit</button>
        </form>
        <div className='text-red-600 text-bold p-3 mt-4 font-bold text-center absolute bottom-0 left-0 right-0'>{error}</div>
      </div>
    </div>
  );
}

export default CourtSignUp;