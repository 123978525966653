import React, { useState, useEffect } from 'react';
import AvailableCourts from './AvailableCourts';
import jsonp from 'jsonp';

function RenterSignUp({toggleSlider, error, setError, setSuccessfulSignup}) {
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('Email');
  const [sport, setSport] = useState('');
  const [city, setCity] = useState('');
  const [frequency, setFrequency] = useState('Weekly');
  const [comment, setComment] = useState('');

  const renterSubmit = (e: any) => {
    e.preventDefault();
    // const url = process.env.REACT_APP_MAILCHIMP_ACTION;

    const formData = {
      EMAIL: email,
      FNAME: fname,
      LNAME: lname,
      PHONE: phone,
      CONT_METH: contactMethod,
      SPORT: sport,
      CITY: city,
      FREQUENCY: frequency,
      COMMENT: comment,
      USER_TYPE: 'RENTER'
    }

    fetch('/api/signuprenter', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(res => res.json())
    .then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        setSuccessfulSignup(true);
      }
    })
  };

  useEffect(() => {
    toggleSlider(false);
  }, []);

  return (
    <div id='renter-signup-container' className='flex-col lg:flex-row'>
      <AvailableCourts />
      <div id='renter-signup-form-container' className='relative pb-4'>
        <h2 className='w-full text-center font-bold' style={{ marginBottom: '10px'}}>RENTER SIGN-UP FORM</h2>
        <form onSubmit={renterSubmit} className='signup-form text-base flex flex-col lg:flex-none sm:grid sm:grid-cols-2' id='renter-signup-form'>
          <label htmlFor='renter-email'>Email:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='renter-email' onChange={(e) => setEmail(e.target.value)} required/>

          <label htmlFor='renter-fname'>First Name:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='renter-fname' onChange={(e) => setFname(e.target.value)} required/>

          <label htmlFor='renter-lname'>Last Name:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='renter-lname' onChange={(e) => setLname(e.target.value)} required/>

          <label htmlFor='renter-phone'>Phone:</label>
          <input className='-mt-4 sm:mt-0' type='tel' id='renter-phone' onChange={(e) => setPhone(e.target.value)} />

          <label htmlFor='renter-contact-method'>Best way to contact?</label>
          <select className='-mt-4 sm:mt-0' id='renter-contact-method' onChange={(e) => setContactMethod(e.target.value)} >
            <option>Email</option>
            <option>Phone</option>
          </select>

          <label htmlFor='renter-sport'>Sport:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='renter-sport' onChange={(e) => setSport(e.target.value)} required/>

          <label htmlFor='renter-city'>City/Region:</label>
          <input className='-mt-4 sm:mt-0' type='text' id='renter-city' onChange={(e) => setCity(e.target.value)} required/>

          <label htmlFor='renter-rental-frequency'>How often do you want to rent?</label>
          <select className='-mt-4 sm:mt-0' id='renter-rental-frequency' onChange={(e) => setFrequency(e.target.value)} >
            <option>Weekly</option>
            <option>Monthly</option>
            <option>Just Once</option>
          </select>

          <label htmlFor='renter-comment' style={{gridColumn: 'span 2'}}>Anything else we should know?</label>
          <textarea
            className='-mt-4'
            type='text'
            id='renter-comment'
            style={{gridColumn: 'span 2', height: '100px'}}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <button className='btn-primary w-22 m-auto col-span-2' type="submit">Submit</button>
        </form>
        <div className='text-red-600 text-bold p-3 mt-4 font-bold text-center absolute bottom-0 left-0 right-0'>{error}</div>
      </div>
    </div>
  );
}

export default RenterSignUp;