import React, { useState, useEffect } from 'react';
import RenterSignUp from './RenterSignUp';
import CourtSignUp from './CourtSignUp';

function SignUp({as, toggleSlider}) {

  const [error, setError] = useState('');
  const [successfulSignup, setSuccessfulSignup] = useState(false);

  useEffect(() => {
    setError('');
    setSuccessfulSignup(false);
  }, [as]);

  return (
    <>
      <div id='signup-page' className='flex flex-auto flex-col flex-grow mt-32 sm:mt-44 mb-28 sm:mb-20'>
        <div id='signup-form-container' className='w-full'>
          {
            successfulSignup
            ?
            <div className='bg-black text-white flex flex-col gap-4 box-border p-5' id='successful-signup-message'>
              Thank you for reaching out. Someone will contact you soon.
            </div>
            :
            <>
            { as === 'renter'
            ?
            <RenterSignUp toggleSlider={toggleSlider} error={error} setError={setError} setSuccessfulSignup={setSuccessfulSignup}/>
            :
            <CourtSignUp toggleSlider={toggleSlider} error={error} setError={setError} setSuccessfulSignup={setSuccessfulSignup}/>
            }
            </>
          }

        </div>
      </div>
    </>
  );
}

export default SignUp;
