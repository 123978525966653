import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import NavDropdown from './NavDropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function NavBar({toggleSlider}) {

  const [hoveredItem, setHoveredItem] = useState('')

  return (
    <div id='navbars-container' className='h-full'>
      <div id='navbar' className='relative mx-10 sm:ml-0 sm:mr-5 flex gap-4 text-sm md:text-base'>
        <NavLink className='top-nav-item' to='/'>ABOUT</NavLink>
        {/* <NavLink className='top-nav-item' onMouseEnter={() => setHoveredItem('about')} onMouseLeave={() => setHoveredItem('')} to='/about'>
          ABOUT
          {hoveredItem === 'about' &&
            <NavDropdown dropdownItems={['LINK1', 'LINK2', 'LINK3']}/>
          }
        </NavLink> */}
        <NavLink className='top-nav-item' to='/signupasrenter'>RENT A COURT</NavLink>
        <NavLink className='top-nav-item' to='/signupascourt'>LIST A COURT</NavLink>
        <NavLink className='top-nav-item' to='/news'>NEWS & EVENTS</NavLink>
        <NavLink className='top-nav-item' to='/fairplay'>FAIR PLAY</NavLink>
      </div>
      <div id='mobile-navbar' className='h-full w-fit flex items-center justify-center mx-10'>
        <FontAwesomeIcon className='float-right cursor-pointer' icon={faBars} color={'white'} size={'xl'} onClick={() => toggleSlider()}/>
      </div>
    </div>
  );
}

export default NavBar;
